















































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import QuestionAudioQueueForm from '@/components/views/exercise/question/QuestionAudioQueueForm.vue'
import QuestionAudioSingleForm from '@/components/views/exercise/question/QuestionAudioSingleForm.vue'
import QuestionTextQueueForm from '@/components/views/exercise/question/QuestionTextQueueForm.vue'
// store
import { EducationLargeExerciseResource, EducationLargeTaskDecideSpeakingQuestionRequest, EducationLargeTaskResource, EducationLargeTaskSpeakingQuestionResource, SpeakingQuestionType } from '@/store/types'
@Component({
  components: {
    QuestionAudioQueueForm,
    QuestionAudioSingleForm,
    QuestionTextQueueForm,
  },
})
export default class SpeakingQuestionPassingView extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private formQuestion!: EducationLargeTaskDecideSpeakingQuestionRequest

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get mediaCount() {
    return this.question.media.length
  }

  // Нечетное ли кол-во медиа
  private get isEvenMediaCount() {
    return Boolean(this.mediaCount % 2)
  }

  private get isQuestionAudioSingle() {
    return this.question.type === SpeakingQuestionType.AUDIO_SINGLE
  }

  private get isQuestionTextQueue() {
    return this.question.type === SpeakingQuestionType.TEXT_QUEUE
  }

  private get isQuestionAudioQueue() {
    return this.question.type === SpeakingQuestionType.AUDIO_QUEUE
  }
}
